import './assets/css/vendor/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import {getPathRoute, getUrlPathRoute} from './helpers/Utils';
import {loadConfigUI, uiConfig} from './helpers/UtilsUI';


import * as Sentry from "@sentry/react";
import {Offline as OfflineIntegration} from "@sentry/integrations";

/*import sworket from './sworket'

sworket()*/

const render = async (productRol) => {
    const {defaultColor, type, ...theme} = await uiConfig(productRol);

    if (defaultColor !== undefined) {
        import(`./assets/css/sass/themes/gogo.${defaultColor}.scss`).then(() => {
            if (theme) {
                loadConfigUI(theme);
            }
            require('./AppRenderer');
        });
    } else {
        if (theme) {
            loadConfigUI(theme);
        }
        require('./AppRenderer');
    }
}
console.log(`%c MT-Explorer :)`, 'background: #222; color: #bada55; font-size:24px');

if (process.env.REACT_APP_PRODUCTION === 'true') {
    Sentry.init({
        dsn: "https://b7dd2da0548f4b1bbbc42199277d6b97@o433423.ingest.sentry.io/5748903",
        integrations: [new OfflineIntegration(
            {
                maxStoredEvents: 30
            }
        )]
    });
    console.log(`%c ENV/PROD`, 'background: #222; color: #D9A42f; font-size:24px');
} else {
    console.log(`%c ENV/DEV`, 'background: #222; color: #D9A42f; font-size:24px');
}

console.log(`%c Versión: ${process.env.REACT_APP_VERSION}`, 'background: #222; color: #569F4A; font-size:24px');
const productRol = getPathRoute();
const urlProductRol = getUrlPathRoute();
(urlProductRol === null) ? productRol !== null ? render(productRol) : render() : render(urlProductRol);


const origin = console.error;
console.error = (error) => {
    console.log("Tiene error " + error);
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
        alert('A new version released. Need to reload the page to apply changes.')
        window.location.reload();
    } else {
        origin(error);
    }
    if (/Refused to execute script from/.test(error.message)) {
        console.log("Refused to execute script from");
        window.location.reload();
    }
    if (/Refused to apply style from/.test(error.message)) {
        console.log("Refused to apply style from");
        window.location.reload();
    }
    if (/Cannot read properties of/.test(error.message)) {
        console.log("Refused to apply style from");
        window.location.reload();
    }

    if (/Uncaught SyntaxError: Unexpected token/.test(error.message)) {
        console.log("Refused to apply style from");
        window.location.reload();
    }
    if (/Uncaught (in promise) ChunkLoadError:/.test(error.message)) {
        console.log("Refused to apply style from");
        window.location.reload();
    }


}
