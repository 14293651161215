import { lpt, isa, land, scr, hcg, hml, fbh, fbfap } from "../constants/defaultValues";

export const onUserLogin = (values, type) => {
  let login = {};
  switch (type) {
    case land:
      let { uid, password, ...props } = values;
      uid = typeof uid === 'object' ? uid.value : uid;
      password = typeof password === 'object' ? password.value : password;
      login = { uid, password, type, role: 'user', ...props  };
      break;
    case 'url':
      login = { ...values, type: 'land', role: 'user' };
      break;
    case isa:
      login = { uid: 0, type, role: 'guest' };
      break;
    case lpt:
      login = { uid: 0, type, role: 'guest' };
      break;
    case scr:
      login = { uid: 0, type, role: 'guest' };
      break;
    case hcg:
      login = { uid: 0, type, role: 'guest' };
      break;
    case hml:
      login = { uid: 0, type, role: 'guest' };
      break;
    case fbh:
      login = { uid: 0, type, role: 'guest' };
      break;
    case fbfap:
      login = { uid: 0, type, role: 'guest' };
      break;

    default:
      if (values.room !== '' && values.pin !== '') {
        login = { 'noRoom': values.room, 'pin': values.pin, type };
      }
      break;
  }
  return login;
};


export const objectUser = (userLogin) => {
  const { uid, ...rest } = userLogin.currentUser;
  const { menu, modules, wallet, walletImage } = userLogin;
  return { uid, ...rest, menu, modules, wallet, walletImage };
}
