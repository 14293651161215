import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import store from './redux/store';
import {createInstance, MatomoProvider} from '@datapunt/matomo-tracker-react'
import {AppInsightsContext} from "@microsoft/applicationinsights-react-js";
import {reactPlugin} from "./ApplicationInsigths";
import {BrowserRouter} from 'react-router-dom';

const instance = (process.env.REACT_APP_PRODUCTION === 'true') ? createInstance({
    urlBase: process.env.REACT_APP_ANALYTICS,
    linkTracking: false, // Important!
    siteId: 1
}) : createInstance({
    urlBase: process.env.REACT_APP_ANALYTICS,
    linkTracking: false, // Important!
    siteId: 2
});


const App = React.lazy(() => import('./App'));

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <AppInsightsContext.Provider value={reactPlugin}>
        <MatomoProvider value={instance}>
            <Provider store={store}>
                <Suspense fallback={<div className="loading"/>}>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </Suspense>
            </Provider>
        </MatomoProvider>
    </AppInsightsContext.Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();


