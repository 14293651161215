export const Types = {
    SETTINGS_CHANGE_LOCALE: 'CHANGE_LOCALE',
    SETTINGS_CHANGE_PRODUCT: 'CHANGE_PRODUCT',
    /* LOGIN */
    LOGIN_CLEAR_ERROR : 'LOGIN_CLEAR_ERROR',
    LOGIN_USER_ERROR : 'LOGIN_USER_ERROR',
    LOGIN_USER_SUCCESS : 'LOGIN_USER_SUCCESS',
    LOGIN_USER: 'LOGIN_USER',
    LOGOUT_USER: 'LOGOUT_USER',
    /* CURRENTUSER */
    CURRENTUSER_ADD_MODULES: 'CURRENTUSER_ADD_MODULES',
    CURRENTUSER_MENU_RELOAD: 'CURRENTUSER_MENU_RELOAD',
    CURRENTUSER_MMW_RELOAD: 'CURRENTUSER_MMW_RELOAD',
    CURRENTUSER_UPDATE_NAME: 'CURRENTUSER_UPDATE_NAME',
    /* MENU */
    MENU_CHANGE_DEFAULT_CLASSES : 'MENU_CHANGE_DEFAULT_CLASSES',
    MENU_CHANGE_HAS_SUB_ITEM_STATUS :'MENU_CHANGE_HAS_SUB_ITEM_STATUS',
    MENU_CLICK_MOBILE_MENU : 'MENU_CLICK_MOBILE_MENU',
    MENU_CONTAINER_ADD_CLASSNAME : 'MENU_CONTAINER_ADD_CLASSNAME',
    MENU_SET_CLASSNAMES : 'MENU_SET_CLASSNAMES',

    /* REGISTRO */
    REGISTRO_HUESPED: 'REGISTRO_HUESPED',
    REGISTRO_HUSPED_ERROR: 'REGISTRO_HUSPED_ERROR',
    REGISTRO_HUSPED_SATISFACTORIO: 'REGISTRO_HUSPED_SATISFACTORIO',
    REGISTRO_LIMPIAR: 'REGISTRO_LIMPIAR',
    REGISTRO_NO_EXISTE: 'REGISTRO_NO_EXISTE',
    REGISTRO_OBTENER_SATISFACTORIO: 'REGISTRO_GET_SATISFACTORIO',
    REGISTRO_OBTENER: 'REGISTRO_GET',

    /* NOTIFICACIONES */
    NOTIFICACION_ENTRANTE: 'NOTIFICACION_INGRESA',
    NOTIFICACION_LIMPIAR: 'NOTIFICACION_LIMPIAR',
    NOTIFICACION_SELECCIONADA: 'NOTIFICACION_SELECCIONADA',
    NOTIFICACION_VISTA: 'NOTIFICACION_VISTA',
}