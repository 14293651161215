import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { FetchApiNoToken } from '../../helpers/fetchApi'
import { objectUser } from '../../helpers/onUserLogin'
import { setCurrentUser } from '../../helpers/Utils'
import { Types } from '../types'
import { loginUserError, loginUserSuccess } from './actions'
import { changeNavBarLogoUser, getCurrentTheme, setCurrentTheme } from '../../helpers/UtilsUI'
import { setRefreshToken, setToken } from '../../helpers/jwt'

function* wacthLoginUser() {
    yield takeEvery(Types.LOGIN_USER, loginCode);
}

function* loginCode({ payload }) {
    const login = payload;
    try {
        const userLogin = yield call(loginWith, login);

        const user = objectUser(userLogin,login);
        setCurrentUser(user);
        setToken(user.secret_id)
        localStorage.setItem("secret_id", user.secret_id)
        setRefreshToken(user.secret_id)
        const loadConfig = getCurrentTheme();
        if (user.charterer && user.charterer.logo) {
            loadConfig["logo"] = user.charterer.logo
            loadConfig["logoMobile"] = user.charterer.logo
        }
        setCurrentTheme(loadConfig);
        changeNavBarLogoUser(loadConfig.logo, loadConfig.logoMobile);

        yield put(loginUserSuccess(user));
    } catch (error) {
        yield put(loginUserError(error));
    }
}

const loginWith = async (body) => {
    try {
        let endpoint = 'api/login/';
        return await FetchApiNoToken(endpoint, body, 'POST');
    } catch (error) {
        console.log('catch', error);
        return { codigo: 'connectionRefused', mensaje: 'connectionRefused' };
    }
}

function logoutClear() {
    localStorage.clear();
    setCurrentUser();
}

function* watchLogout() {
    yield takeEvery(Types.LOGOUT_USER, logoutClear);
}

export default function* rootSaga() {
    yield all([
        fork(wacthLoginUser),
        fork(watchLogout)
    ]);
}
