import { Types } from "../types";

const { getCurrentLanguage, getCurrentProduct } = require("../../helpers/Utils")

const initialState = {
    locale: getCurrentLanguage(),
    type: getCurrentProduct()
}

const settings = (state = initialState, action) =>{
    switch (action.type) {
        case Types.SETTINGS_CHANGE_LOCALE:
            return {
                ...state,
                locale: action.payload
            };
        case Types.SETTINGS_CHANGE_PRODUCT:
            return {
                ...state,
                type: action.payload
            }
        default:
            return {...state};
    }
}

export default settings;