import { combineReducers } from 'redux';

import settings from './settings/reducer';
import auth from './auth/reducer';
import menu from './menu/reducer';
import registro from './registro/reducer';
import notifications from './notification/reducer';
const reducers = combineReducers({
    settings,
    auth,
    menu,
    registro,
    notifications
});

export default reducers;
