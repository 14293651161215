import { getIn } from 'formik';
import React from 'react';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeRadiusStorageKey,
  defaultProd,
  PRODUCTS,
} from '../constants/defaultValues';
import { fetchSinTokenReturnJSON } from './fetch';
import IntlMessages from './IntlMessages';
import { scroller } from 'react-scroll';
import { getCurrentTheme, getTheme } from './UtilsUI';
import moment from 'moment';

export const deshacer = () => {
  const raiz = document.getElementById("raiz");
  raiz.classList.remove("fondo-html");
  const cuerpo = document.getElementById("cuerpo");
  cuerpo.classList.remove("cuerpo-transparente");
}

export const fondoHome = () => {
  const raiz = document.getElementById("raiz");
  raiz.classList.add("fondo-html");
  const cuerpo = document.getElementById("cuerpo");
  cuerpo.classList.add("cuerpo-transparente");
}

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getDirection -> error", error)
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setDirection -> error", error)
  }
};


export const getCurrentFavIcon = () => {
  return document.getElementById('favicon');
}

export const setCurrentFavIcon = (currentFavIcon) => {
  const newFavicon = null;
  if (newFavicon) {
    currentFavIcon.href = newFavicon;
  }
}

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentRadius -> error", error)
    currentRadius = 'rounded'
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentRadius -> error", error)
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language = localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
      ? localStorage.getItem('currentLanguage')
      : defaultLocale;
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentLanguage -> error", error)
  }
  return language;
};

export const getCurrentImage = (tipo, mapa) => {
  let image = null;
  try {
    image = mapa[tipo];
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentImage -> error", error);
  }
  return image;
}

export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentLanguage -> error", error);
  }
};
/**
 *
 * @returns Array  # land, hcg
 */
export const getCurrentProduct = () => {
  let prod = defaultProd;
  try {
    if (localStorage.getItem(`__currentProd_${defaultProd}`) !== null) {
      prod = localStorage.getItem(`__currentProd_${defaultProd}`);
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : getCurrentProduct -> error", error)
  }
  return prod;
}
/**
 * Almacen en el localstorage el valor del producto
 * @param {String} product
 */
export const setCurrentProduct = (product) => {
  try {
    localStorage.setItem(`__currentProd_${defaultProd}`, product);
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentProduct -> error", error);
  }
}

export const deleteProduct = () => {
  localStorage.removeItem(`__currentProd_${defaultProd}`);
}
/**
 * Obtiene el usuario por defecto
 * @returns Object user
 */
export const getCurrentUser = () => {
  try {
    return getLocalStorageUser();
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error)
  }

}

export const getLocalStorageUser = () => {
  return localStorage.getItem(`${defaultProd}_current_user`) !== null
    ? JSON.parse(localStorage.getItem(`${defaultProd}_current_user`))
    : {
      roles: { guest: true },
      modules: []
    };
}

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem(`${defaultProd}_current_user`, JSON.stringify(user))
    } else {
      localStorage.removeItem(`${defaultProd}_current_user`)
    }
  } catch (error) {
    console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error)
  }
};


export const formatNumber = (phone) => {
  return phone.trim('').replace('+', '').replace(/\s/g, '');
}

export const reactSelectFormat = (obj, v, l, l2, init = null) => {
  let format = [];
  if (init !== null) format = [{ value: '', label: init }];
  Object.keys(obj).forEach(e => {
    if (l2 !== undefined) {
      if (v === '') {
        format.push({ value: obj[e], label: obj[e][l] + '  ' + obj[e][l2] });
      } else {
        format.push({ value: obj[e][v], label: obj[e][l] + '  ' + obj[e][l2] });
      }
    } else {
      format.push({ value: obj[e][v], label: obj[e][l] });
    }
  });
  return format;
}

/* FORM VALIDATORS */
export const fieldRequired = (value) => {
  let error;
  if (!value) {
    error = <IntlMessages id='validate.required' />
  }
  return error;
}

let common_special_chars = ['|', '!', '"', '#', '$', '%', '&', '/', '(', ')', '=', '?', '¿', '¡', '@', '*', '+', '-', '.', ',', '_', '{', '}', '[', ']', '<', '>'];

export const nameFieldValidator = (value) => {
  let error;
  if (!value || String(value).length < 3) {
    error = <IntlMessages id='validate.required' />
  } else {
    for (let c in common_special_chars) {
      if (value.indexOf(c) >= 0) {
        error = <IntlMessages id='validate.invalid-value' />;
        break;
      }
    }
  }
  return error;
}

export const phoneFieldValidator = (value) => {
  let error;
  let pattern = /^[+]?\d{1,20}([- ]?\d{1,20}){1,5}$/;
  if (!value || !pattern.test(value)) {
    error = <IntlMessages id='validate.invalid-phone' />
  }
  return error;
}

export const textFieldValidator =  (value) => {
  let error;
  let exlude_text_chars = ['|', '@', '#', '$', '=']
  if (!value || String(value).length < 5) {
    error = <IntlMessages id='validate.required' />
  } else if (value) {
    for (let c in exlude_text_chars) {
      if (value.indexOf(c) >= 0) {
        error = <IntlMessages id='validate.invalid-value' />;
        break;
      }
    }
  }
  return error;
}

export const getStyles = (errors, fieldName) => {
  if (getIn(errors, fieldName)) {
    return {
      border: '1px solid red'
    }
  }
}

export const getRelationshipData = async (locale) => {
  const httpOptions = { headers: { 'Accept-Language': locale } };
  const dataResp = await fetchSinTokenReturnJSON('api/familyrelationships/', '', 'GET', httpOptions);
  return reactSelectFormat(dataResp==null?[]:dataResp, 'code', 'name');
}
export const getPaises = async () => {
  const dataResp = await fetchSinTokenReturnJSON('api/countries', '', 'GET');
  return reactSelectFormat(dataResp==null?[]:dataResp, 'code', 'name');
}
export const getCountries = async (locale) => {
  const httpOptions = { headers: { 'Accept-Language': locale } }
  const dataResp = await fetchSinTokenReturnJSON('api/country', '', 'GET', httpOptions)
  return reactSelectFormat(dataResp == null ? [] : dataResp, 'numeric_code', 'name')
}
export const getStates = async (locale, value = '') => {
  const httpOptions = { headers: { 'Accept-Language': locale } }
  let dataResp = await fetchSinTokenReturnJSON(`api/states?${value}`, '', 'GET', httpOptions)
  dataResp = AddNotApply(dataResp,locale)
  return reactSelectFormat(dataResp == null ? [] : dataResp, 'id', 'name')
}
export const getCities = async (locale, value = '') => {
  const httpOptions = { headers: { 'Accept-Language': locale } }
  let dataResp = await fetchSinTokenReturnJSON(`api/cities?${value}`, '', 'GET', httpOptions)
  dataResp = AddNotApply(dataResp,locale)
  return reactSelectFormat(dataResp == null ? [] : dataResp, 'id', 'name')
}
export const AddNotApply = (dataResp,locale) => 
{
    if(dataResp.length===0){
      if(locale.toString().toUpperCase()==="ES")
        dataResp.push({id:-1,name:"No aplica"})
      else
        dataResp.push({id:-1,name:"Does not apply"})
    }
    return dataResp
}  
/**
 * Si el objeto está vacio retorna TRUE
 * @param {*} obj
 * @returns boolean
 */
export const objetoVacio = (obj) => {
  if(obj==null)obj = []
  return (Object.keys(obj).length === 0)
}

export const notifiCountSave = (num) => {
  localStorage.setItem(`${defaultProd}NotiNum`, num);
}

export const notifiCountGet = () => {
  return Number(localStorage.getItem(`${defaultProd}NotiNum`)) || 0;
}

export const bingoSaveStorage = (code,values)=>{
  localStorage.setItem(code,JSON.stringify(values));
}

export const bingoStorageGet = (code) =>{
  const data = localStorage.getItem(code) || null;
  if(data !== null){
    return JSON.parse(data);
  }else {
    return null;
  }
}
/**
 * Obtiene el tema actual y el tema del producto enviado los fusiona
 * para mantener un historico
 * @param {String} prod
 * @returns Object
 */
export const settingProduct = async (prod) => {

  const originalTheme = getCurrentTheme();
  try {
    const productRol = { product: prod };
    const newTheme = await getTheme(productRol);
    const mixTheme = {
      ...originalTheme, ...newTheme
    }
    return { originalTheme, mixTheme, newTheme };
  } catch (error) {
    console.error("ERROR: settingsProduct - getTheme");
    const newTheme = { defaultColor };
    return { originalTheme, mixtheme: newTheme, newTheme };
  }
}
/**
 * Obtiene todas las propiedades de ui del producto enviado
 * @param {String} product
 * @returns Object
 */
export const getPropertiesProduct = async (product) => {
  const body = {
    product: product
  };
  try {
    return await fetchSinTokenReturnJSON('api/product', body, 'POST');
  } catch (error) {
    console.error('ERROR: getProperties - api/product ', error);
    return { modules: null, menu: null };
  }
}
/**
 *
 * @param {String} valor
 * @returns boolean
 */
export const stringToBoolean = (valor) => {
  return (valor === 'true');
}
/**
 * ScrollTo
 * <div id='destino'></div>
 * @param {Event} event
 * @param {String} target
 * @returns
 */
export const scrollTo = (event, target) => {
  event.preventDefault();
  scroller.scrollTo(target, {
    duration: 500,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: -100
  });
  return false;
}

export const capitalizarPalabra = (texto) => {
  return texto.charAt(0).toUpperCase() + texto.slice(1);

}

export const handleDownload = (heading, pdf) => {
  let a = document.createElement('a');
  a.setAttribute('href', pdf);
  a.setAttribute('target', '_blank');
  a.setAttribute('download', heading);
  a.click();
}

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getPathRoute = () => {
  let product = null;

  for (let prod of PRODUCTS) {
    if (window.location.pathname.includes(prod)) {
      product = prod;
      break;
    }
  }

  const rol = (window.location.pathname.includes('/guest/') || window.location.pathname.endsWith('guest')) ? 'guest' : null;
  if (product === null && rol === null) return null;
  return { product, rol };
}


export const getUrlPathRoute = () => {
  let product = null;
  product = getParameterByName('type', window.location.href) !== null ?
    getParameterByName('type', window.location.href) : null;
  if (product === null) return null
  else return { product };
}


export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

export const diccionarioQuestionAnswer = (values, messages) => {
  const { country, state, city, contactName, contactRelationship, contactTelephone,
    contactCountry, ...rest } = values;
  return {
    country,
    state,
    city,
    contactName,
    contactRelationship,
    contactTelephone,
    contactCountry,
    covidInformation: [
      {
        question: messages['questionCovid1'],
        answer: rest.vaccinated,
      },
      {
        question: messages['questionCovid2'],
        answer: rest.fullDoseVaccine,
      },
      {
        question: messages['questionCovid3'],
        answer: rest.testPCR,
      },
      {
        question: messages['questionCovid4'],
        answer: rest.resultPCR,
      },
      {
        question: messages['questionCovid5'],
        answers: [
          {
            question: messages['questionHealth3.1'],
            answer: values.q31,
            q31: { question: messages['questionHealth3.X'], answer: values.a31 }
          },
          {
            question: messages['questionHealth3.2'],
            answer: values.q32,
            q32: { question: messages['questionHealth3.X'], answer: values.a32 }
          },
          {
            question: messages['questionHealth3.3'],
            answer: values.q33,
            q33: { question: messages['questionHealth3.X'], answer: values.a33 }
          },
          {
            question: messages['questionHealth3.4'],
            answer: values.q34,
            q34: { question: messages['questionHealth3.X'], answer: values.a34 }
          },
          {
            question: messages['questionHealth3.5'],
            answer: values.q35,
            q35: { question: messages['questionHealth3.X'], answer: values.a35 }
          },
          {
            question: messages['questionHealth3.6'],
            answer: values.q36,
            q36: { question: messages['questionHealth3.X'], answer: values.a36 }
          },
          {
            question: messages['questionHealth3.7'],
            answer: values.q37,
            q37: { question: messages['questionHealth3.X'], answer: values.a37 }
          },
          {
            question: messages['questionHealth3.8'],
            answer: values.q38,
            q38: { question: messages['questionHealth3.X'], answer: values.a38 }
          },
          {
            question: messages['questionHealth3.9'],
            answer: values.q39,
            q39: { question: messages['questionHealth3.X'], answer: values.a39 }
          },
          {
            question: messages['questionHealth3.10'],
            answer: values.q310,
            q310: { question: messages['questionHealth3.X'], answer: values.a310 }
          },
          {
            question: messages['questionHealth3.11'],
            answer: values.q311,
            q311: { question: messages['questionHealth3.X'], answer: values.a311 }
          },
          {
            question: messages['questionHealth3.12'],
            answer: values.q312,
            q312: { question: messages['questionHealth3.X'], answer: values.a312 }
          },
          {
            question: messages['questionHealth3.13'],
            answer: values.q313,
          },
        ]
      }
    ],

    preexistingIllnesses: [
      {
        question: messages['questionCovid6.1'],
        answer: rest.cardiovascularIllness,
        details: { question: messages['questionCovid6.X'], answer: values.medicationPrescribedCardiovascular }
      },
      {
        question: messages['questionCovid6.2'],
        answer: rest.respitatoryIllness,
        details: { question: messages['questionCovid6.X'], answer: values.medicationPrescribedRespiratory }
      },
      {
        question: messages['questionCovid6.3'],
        answer: rest.endocrineIllness,
        details: { question: messages['questionCovid6.X'], answer: values.medicationPrescribedEndocrine }
      },
      {
        question: messages['questionCovid6.4'],
        answer: rest.neurologicalIllness,
        details: { question: messages['questionCovid6.X'], answer: values.medicationPrescribedNeurological }
      },
      {
        question: messages['questionCovid6.5'],
        answer: rest.anyIllness,
        details: { question: messages['questionCovid6.X'], answer: values.medicationPrescribedAnyIllness }
      }
    ],
    otherSatesHealth: [
      {
        question: messages['questionotherSatesHealth1'],
        answer: rest.pregnat,
      },
      {
        question: messages['questionotherSatesHealth2'],
        answer: rest.specialCapacity,
      },
      {
        question: messages['questionotherSatesHealth3'],
        answer: rest.allergies,
        details: {
          question: messages['questionotherSatesHealth3.1'],
          answer: rest.detailAllergic,
        }
      }
    ],
    insuranceInformation: {
      question: messages['insuranceInformationQuestion'],
      answer: rest.insurance,
      details:{
        question:messages['companyName'],
        answer: rest.companyName
      }
    }
  }
}

export const days31 = (n = 31) => {
  let days = [];
  for (let i = 1; i <= n; i++) {
    days.push({ value: i, label: i, name: i });
  }
  return days;
}
export const months12 = (n = 12) => {
  let months = [];
  for (let i = 1; i <= n; i++) {
    months.push({ value: i, label: i, name: i });
  }
  return months;
}

export const yearBack = (n) => {
  let years = [];
  let currentYear = moment().format('YYYY');
  const backYear = Number(currentYear) - Number(n);
  for (let i = currentYear; i >= backYear; i--) {
    years.push({ value: i, label: i, name: i });
  }
  return years;
}

export const getSigning = (code) => {
  let codeSigning = 0xFFFFFFFF
  let number = (codeSigning + (code * 365) + 1)
  number = number.toString(16).toUpperCase()
  return number
}

export const isEmpty = (value) => {
  return (
    (value == null) ||
    (value.hasOwnProperty('length') && value.length === 0) ||
    (value.constructor === Object && Object.keys(value).length === 0)
  )
}