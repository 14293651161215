const { Types } = require("../types");


export const sendRegistry = ( registry ) =>({
  type: Types.REGISTRO_HUESPED,
  payload: registry  
});

export const registryError = ( message) =>({
  type: Types.REGISTRO_HUSPED_ERROR,
  payload: message
});

export const registrySuccess = () =>({
  type: Types.REGISTRO_HUSPED_SATISFACTORIO
});

export const getRegistry = ( guest ) =>({
  type: Types.REGISTRO_OBTENER,
  payload: guest
});

export const registryNotFound = () =>({
  type: Types.REGISTRO_NO_EXISTE
});

export const getRegistrySuccess = (guestSelected) =>({
  type: Types.REGISTRO_OBTENER_SATISFACTORIO,
  payload: guestSelected
});

export const registryClean = ()=>({
  type: Types.REGISTRO_LIMPIAR,
  payload: {}
});