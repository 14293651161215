import { getCurrentUser } from "../../helpers/Utils";
import { Types } from "../types";

const initialState = {
    currentUser: getCurrentUser(),
    loading: false,
    error: ''
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case Types.LOGIN_USER:
            return { ...state, loading: true, error: '' };
        case Types.LOGIN_USER_SUCCESS:
            return { ...state, loading: false, error: '', currentUser: action.payload };
        case Types.LOGIN_USER_ERROR:
            return { ...state, loading: false, error: action.payload };
        case Types.LOGIN_CLEAR_ERROR:
            return { ...state, error: '' };
        case Types.LOGOUT_USER:
            return { ...state, error: '', currentUser: action.payload };
        case Types.CURRENTUSER_MENU_RELOAD:
            return { ...state, error: '', currentUser: action.payload };
        case Types.CURRENTUSER_ADD_MODULES:
            return { ...state, error: '', currentUser: action.payload };
        case Types.CURRENTUSER_UPDATE_NAME:
            const { firstName, lastName, ...rest } = state.currentUser;
            const currentUserMutated = { ...rest, ...action.payload };
            return { ...state, error: '', currentUser:currentUserMutated }
        default:
            return { ...state };
    }
}

export default auth;