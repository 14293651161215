import { Types } from "../types"

export const loginUser = (user) => ({
    type: Types.LOGIN_USER,
    payload: user
});

export const loginUserSuccess = (user) => ({
    type: Types.LOGIN_USER_SUCCESS,
    payload: user
});
export const loginUserError = (message) => ({
    type: Types.LOGIN_USER_ERROR,
    payload: message
});

export const loginErrorClear = () => ({
    type: Types.LOGIN_CLEAR_ERROR
});

export const logoutUser = () => {
    return {
        type: Types.LOGOUT_USER,
        payload: {}
    }
};
