import { Types } from "../types";

const initialState = {
    notificationsList: [],
    count: 0,
    to:''
}

const notifications = (state = initialState, action) => {
    switch (action.type) {
        case Types.NOTIFICACION_ENTRANTE:
            // console.log('action', action)
            return {
                ...state,
                notificationsList: action.payload.nt,
                count: action.payload.count,
                to: action.payload.to
            }
        case Types.NOTIFICACION_LIMPIAR:
            return { ...state, count:0 };
        default:
            return { ...state };
    }
}

export default notifications;
