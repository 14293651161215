import { Types } from "../types";

const initialState = {
    loading: false,
    error: '',
    guestSelected: {}
}
const registro = (state = initialState, action) => {
    switch (action.type) {
        case Types.REGISTRO_HUESPED:
            return {
                ...state,
                error: '',
                loading: true
            }
        case Types.REGISTRO_HUSPED_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case Types.REGISTRO_HUSPED_SATISFACTORIO:
            return {
                ...state,
                error: '',
                loading: false
            }
        case Types.REGISTRO_OBTENER:
            return {
                ...state,
                error: '',
                loading: true
            }
         case Types.REGISTRO_OBTENER_SATISFACTORIO:
             return {
                 ...state,
                 guestSelected: action.payload,
                 error:'',
                 loading: false
             }   
         case Types.REGISTRO_LIMPIAR:
             return {
                 ...state,
                 guestSelected: action.payload,
                 error:'',
                 loading: false
             }
         case Types.REGISTRO_NO_EXISTE:
             return {
                 ...state,
                 guestSelected: {idRegistry:0},
                 error:'',
                 loading: false   
             }

        default:
            return { ...state }
    }
}

export default registro;