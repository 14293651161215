import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchSinTokenReturnJSON } from "../../helpers/fetch";
import { objetoVacio } from "../../helpers/Utils";
import { Types } from "../types";
import { getRegistrySuccess, registryError, registryNotFound, registrySuccess } from "./actions";

const registryAsync = async (registry)=>{
        return  await fetchSinTokenReturnJSON('api/registry/save',registry,'POST');
} 

function* sendRegistry({payload}) {
    const registry = payload;
    try {
        const registryResponse = yield call(registryAsync, registry); 
        if(registryResponse.codigo !== undefined && registryResponse.codigo ==='ok') {
            yield put(registrySuccess());
        }else {
           yield put(registryError('internalError')); 
        }
    } catch (error) {
        yield put(registryError('connectionRefused'));
    }
}

function* watchRegistry(){
    yield takeEvery(Types.REGISTRO_HUESPED,sendRegistry);
}

function* watchGetRegistry() {
    yield takeEvery(Types.REGISTRO_OBTENER, getRegistry);
}

function* getRegistry({payload}) {
    const id = payload;
        const registryResponse = yield call(getRegistryAsync,id);
        if(! objetoVacio(registryResponse)) {
            const guestSelected = registryResponse.registry == null ? {idRegistry:0}:registryResponse.registry;
            yield put(getRegistrySuccess(guestSelected));
        }else {
            yield put(registryNotFound());   
        }
}

const getRegistryAsync = async(id)=>{
    try {
        return await fetchSinTokenReturnJSON(`/api/registry/${id}`,'GET');
    } catch (error) {
        console.log('error',error);
        return {};
    }
}


export default function* rootSaga() {
    yield all([
        fork(watchRegistry),
        fork(watchGetRegistry)
    ]);
}