import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';


const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '2f00148d-73b4-4ad3-a2f5-221828d8af9e',
        extensions: [reactPlugin],
        enableAutoRouteTracking: true
    }
});
appInsights.loadAppInsights();
export {reactPlugin, appInsights};
