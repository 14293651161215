import {defaultMenuType, subHiddenBreakpoint, menuHiddenBreakpoint } from '../../constants/defaultValues';
import { Types } from '../types';

const initialState = {
    containerClassnames: defaultMenuType,
    subHiddenBreakpoint,
    menuHiddenBreakpoint,
    menuClickCount: 0,
    selectedMenuHasSubItems: defaultMenuType === 'menu-default', 
}

const menu = (state = initialState, action) =>{
    switch (action.type) {
        case Types.MENU_SET_CLASSNAMES:
            return {
                ...state,
                containerClassnames: action.payload.containerClassnames,
                menuClickCount: action.payload.menuClickCount,
            };

        case Types.MENU_CLICK_MOBILE_MENU:
            return {
                ...state,
                containerClassnames: action.payload.containerClassnames,
                menuClickCount: action.payload.menuClickCount,
            };
        case Types.MENU_CONTAINER_ADD_CLASSNAME:
            return { ...state, containerClassnames: action.payload };

        case Types.MENU_CHANGE_DEFAULT_CLASSES:
            return { ...state, containerClassnames: action.payload };
            
        default:
            return state;
    }
}

export default menu;