import {getToken, jwtConfig, obtainToken, refreshToken, setRefreshToken, setToken} from './jwt'

const urlBackendDefault = process.env.REACT_APP_BACKEND

const getParamsUrl = (url, params) => {
    let urlParams = url
    if (params !== undefined) {
        if (Array.isArray(params)) {
            urlParams = url + '/' + params.join('/')
        } else {
            urlParams = url + params
        }
    }
    return urlParams
}

const getDefaultHeader = () => {
    if (getToken() !== undefined && getToken() !== "undefined") {
        return {
            headers: {
                'Authorization': `${jwtConfig.tokenType} ${getToken()}`,
                'Content-type': 'application/json',
            },
        }
    } else {
        return {
            headers: {
                'Content-type': 'application/json',
            },
        }
    }
}

const getJWTFetch = async (url, options) => {
    let resultTmp = await fetch(url, options)
    if (!resultTmp.ok) {
        let error = resultTmp.statusText
        if (resultTmp.status === 401) {
            let currentUsrLogin = JSON.parse(localStorage.getItem("land_current_user"))
            let code = currentUsrLogin["uid"] !== undefined ? currentUsrLogin["uid"] : 0
            let result = await refreshToken(options)
            if (!result.ok) {
                result = await obtainToken(options, code)
            }
            let res = await result.json()
            setToken(res.access)
            setRefreshToken(res.refresh)
            return getJWTFetch(url, {...options, ...getDefaultHeader()})
        }
        if (resultTmp.status !== 401) {
            let errors = await resultTmp.json()
            error = errors.error
        }
        return Promise.reject(new Error(error))
    }
    return await resultTmp.json()
}

const fetchSinToken = (endpoint, data, method = 'GET', httpOptions = null, signal=null, baseUrl = null) => {
    baseUrl = baseUrl === null ? urlBackendDefault : baseUrl
    const url = `${baseUrl}/${endpoint}`
    if (method === 'GET') {
        if (httpOptions == null) {
            return fetch(url)
        }
        if (httpOptions !== null) {
            let urlParams = url
            if (httpOptions.params !== undefined) {
                if (Array.isArray(httpOptions.params)) {
                    urlParams = url + '/' + httpOptions.params.join('/')
                } else {
                    urlParams = url + httpOptions.params
                }
            }
            let options = httpOptions.headers === undefined ? {method} : {method, headers: httpOptions.headers}
            options = {...options, signal}
            return fetch(urlParams, options)
        }
    } else {
        if (httpOptions === null) {
            httpOptions = {
                'Content-type': 'application/json',
            }
        }
        return fetch(url, {
            method,
            headers: httpOptions,
            body: JSON.stringify(data),
            signal,
        })
    }
}

const fetchSinTokenReturnJSON = async (endpoint, data, method = 'GET', httpOptions = null, signal, baseUrl = null) => {

    if (!navigator.onLine) {
        if (localStorage.getItem(endpoint) !== null && localStorage.getItem(endpoint) !== '')
            return JSON.parse(localStorage.getItem(endpoint))
        else {
            window.location.href = '/offline.html'
            return null
        }
    }

    baseUrl = baseUrl === null ? urlBackendDefault : baseUrl
    const url = `${baseUrl}/${endpoint}`
    try {
        if (method === 'GET') {
            if (httpOptions === null) {
                let options = getDefaultHeader()
                let resJson = await getJWTFetch(url, options)
                localStorage.setItem(endpoint, JSON.stringify(resJson))
                return resJson
            }

            if (httpOptions !== null) {
                let urlParams = getParamsUrl(url, httpOptions.params)
                let options = {...getDefaultHeader(), signal}
                options.headers = {...options.headers, ...httpOptions.headers}
                let resJson = await getJWTFetch(urlParams, options)
                localStorage.setItem(endpoint, JSON.stringify(resJson))
                return resJson
            }
        } else {
            let options = {method, body: JSON.stringify(data), signal, ...getDefaultHeader()}
            if (httpOptions !== null) {
                options = {...options, headers: httpOptions}
            }
            let resJson = await getJWTFetch(url, options)
            localStorage.setItem(endpoint, JSON.stringify(resJson))
            return resJson
        }
    } catch (ex) {
        if (!navigator.onLine) {
            window.location.href = '/offline.html'
        }
        return null
    }
}

export {
    fetchSinToken,
    fetchSinTokenReturnJSON,
}
