import { applyMiddleware, compose, createStore } from "redux";
import reducers from "./reducers";
import createSagaMiddleware from 'redux-saga';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export function configureStore(inistialState) {
    const _store = createStore(
        reducers,
        inistialState,
        composeEnhancers(applyMiddleware(...middlewares))
    );

    sagaMiddleware.run(sagas);

    return _store
}

const store = configureStore();

export default store;