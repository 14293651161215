import { logoutUser } from '../redux/auth/actions';
import store from '../redux/store';

const urlBackendDefault = process.env.REACT_APP_BACKEND

const getParamsUrl = (url, params) => {
    let urlParams = url
    if (params !== undefined) {
        if (Array.isArray(params)) {
            urlParams = url + '/' + params.join('/')
        } else {
            urlParams = url + params
        }
    }
    return urlParams
}

// Nuevo método para obtener info de las API's backend
const CallApi = async (url, options, use_token=false) => {

    const get_headers = () => {
        let headers = {'Content-type': 'application/json'}
        if (use_token) {
            let token = localStorage.getItem("secret_id");
            headers = {'Authorization': `${token}`, ...headers}
        }
        return headers;
    }

    if (options && options.headers) {
        options.headers = {...get_headers(), ...options.headers}
    } else {
        options = {headers: get_headers(), ...options}
    }

    let resultTmp = await fetch(url, options)

    if (!resultTmp.ok) {
        let errors = await resultTmp.json()
        let error = errors.detail || errors.error;
        if (String(error).indexOf("Authentication credentials") >= 0) {
            store.dispatch(logoutUser());
            store.navigate("/auth/login")
        }
        return Promise.reject(new Error(error))
    }
    return await resultTmp.json()
}

const FetchApi = async (endpoint, data, method = 'GET', httpOptions = null, signal=null, baseUrl = null, use_token=false) => {

    if (!endpoint.endsWith("/")) { // verifica si la URL no termina en "/"
        endpoint += "/"; // agrega "/" al final de la URL
    }

    if (!navigator.onLine) {
        let endpoitCache = localStorage.getItem(`apiCache.${endpoint}`)
        if (endpoitCache) {
            return JSON.parse(endpoitCache)
        } else {
            window.location.href = '/offline.html'
            return null
        }
    }

    baseUrl = baseUrl === null ? urlBackendDefault : baseUrl
    const url = `${baseUrl}/${endpoint}`
    try {
        let resJson;
        if (method === 'GET') {
            if (httpOptions === null) {
                resJson = await CallApi(url, null,use_token)
            }
            if (httpOptions !== null) {
                let urlParams = getParamsUrl(url, httpOptions.params)
                let options = {signal, ...httpOptions}
                resJson = await CallApi(urlParams, options, use_token)
            }
        } else {
            let options = {method, body: JSON.stringify(data), signal}
            if (httpOptions !== null) {
                options = {...options, headers: httpOptions}
            }
            resJson = await CallApi(url, options, use_token)
        }
        localStorage.setItem(`apiCache.${endpoint}`, JSON.stringify(resJson))
        return resJson
    } catch (ex) {
        if (!navigator.onLine) {
            window.location.href = '/offline.html'
        }
        return null
    }
}

const FetchApiNoToken = async (endpoint, data, method = 'GET', httpOptions = null, signal = null, baseUrl = null) => {
    return await FetchApi(endpoint, data, method, httpOptions, signal, baseUrl, false)
}

const FetchApiWithToken = async (endpoint, data, method = 'GET', httpOptions = null, signal = null, baseUrl = null) => {
    return await FetchApi(endpoint, data, method, httpOptions, signal, baseUrl, true)
}


export {
    FetchApiNoToken,
    FetchApiWithToken
}
