export const defaultMenuType = 'menu-default';


export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'es';
export const localeOptions = [

    { id: 'en', name: 'English'},
    { id: 'es', name: 'Español'},
    { id: 'de', name: 'Deutsch'}
];
// --
// PRODUCTOS
export const land = 'land';
export const fbh = 'fbh';
export const fbfap = 'fbfap';
export const hcg = 'hcg';
export const hml = 'hml';
export const scr = 'scr';
export const lpt = 'lpt';
export const isa = 'isa';
export const PRODUCTS = [land, scr, lpt, isa, hml, fbh, fbfap, hcg];
export const emailPassword = 'emailPassword';

export const guestRoot = 'guest';
export const defaultProd = land;


export const themeStorageKey = `__theme_${defaultProd}`;
export const isMultiColorActive = true;
export const defaultColor = 'light.greysteel';
export const isDarkSwitchActive = false;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = false;
export const colors = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
];


export const whatsappNumber = '+593 999 722 942';

export const defaultMenu = [
    {
        "id": "home",
        "icon": "simple-icon-home",
        "label": "menu.home",
        "to": `/${defaultProd}/home`
    }
];

export const defaultModules = [
    {
        "path": `/${defaultProd}/home`,
        "type": "home"
    }
];

export const UserRole = ["user", "admin"];
export const GuestRole = ["guest"];

