import { getSigning } from '../helpers/Utils';
// ** Auth Endpoints

const urlBackendDefault = process.env.REACT_APP_BACKEND
const jwtConfig = {  // TDOD: Eliminar
    refreshEndpoint: `${urlBackendDefault}/api/token/refresh/`,
    verifyEndpoint: `${urlBackendDefault}/api/token/verify/`,
    tokenEndpoint: `${urlBackendDefault}/api/token/`,

    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
}

const getToken = () => {
    return localStorage.getItem(jwtConfig.storageTokenKeyName)
}

const getRefreshToken = () => {
    return localStorage.getItem(jwtConfig.storageRefreshTokenKeyName)
}

const setToken = (value) => {
    localStorage.setItem(jwtConfig.storageTokenKeyName, value)
}

const setRefreshToken = (value) => {
    localStorage.setItem(jwtConfig.storageRefreshTokenKeyName, value)
}

const refreshToken = (options) => {
    return fetch(jwtConfig.refreshEndpoint, {
        ...options,
        method: 'POST',
        body: JSON.stringify({ refresh: getRefreshToken() }),
    })
}

const obtainToken = (options, code) => {
    let token = getSigning(code)
    return fetch(jwtConfig.tokenEndpoint, {
        ...options,
        method: 'POST',
        body: JSON.stringify({ refresh: getRefreshToken() }),
        headers: {
            'Content-type': 'application/json',
            "Authorization": `MT Token ${token}`
        }
    })
}

export { jwtConfig, getToken, getRefreshToken, setToken, setRefreshToken, refreshToken, obtainToken }
 