import { getCurrentImage, getLocalStorageUser } from "./Utils";
import { defaultColor, defaultProd, themeStorageKey } from "../constants/defaultValues";
import { fetchSinTokenReturnJSON } from "./fetch";
/**
 * User interface config
 * @returns Object loadConfig
 */
export const uiConfig = async (productRol) => {
    let loadConfig = {};
    try {
        const user = getLocalStorageUser();

        if (user.uid === undefined || ( productRol!==undefined && productRol.rol!==undefined && productRol.rol!==undefined && productRol.rol==="guest")) { // obtiene el theme si no esta logueado
            loadConfig = await getTheme(productRol);
            if(loadConfig!==null)
                setCurrentTheme(loadConfig);
            else
                loadConfig = getCurrentTheme();
        } else { // mantiene el thema seleccionado mientras está logueado
            loadConfig = getCurrentTheme();
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : ui-config -> productRol:" + productRol + " -> error", error);
        //setCurrentTheme(loadConfig);
        loadConfig = {
            defaultColor,
            type: defaultProd
        };
    }
    console.log(loadConfig)
    return loadConfig;
}

function getFaviconTheme() {
    return document.getElementById('favicon');
}

export const getFavIcon = (config) => {
    const favicon = getFaviconTheme();
    favicon.href = config.favicon;
}

export const changeNavBarLogoUser = (logo, logoMobile) => {
    for (var i = 0; i < document.styleSheets.length; i++) {
        var sheet = document.styleSheets[i];
        for (var j = 0; j < sheet.cssRules.length; j++) {
            var rule = sheet.cssRules[j];

            if (rule.selectorText === ".navbar-start-logo .logo") {
                rule.style.background = `url(${logo}) no-repeat center / contain`;
            }

            if (rule.selectorText === ".navbar-start-logo .logo-mobile") {
                rule.style.background = `url(${logoMobile}) no-repeat center / contain`;
            }
        }
    }
}

/**
 * Acepta el objeto config el cual tiene las configuraciones a cambiar en la
 * interfaz de usuario
 * @param {Object} config
 */
export const loadConfigUI = (config) => {
    try {
        getFavIcon(config);
        for (var i = 0; i < document.styleSheets.length; i++) {
            var sheet = document.styleSheets[i];
            for (var j = 0; j < sheet.cssRules.length; j++) {
                var rule = sheet.cssRules[j];

                if (rule.selectorText === ".logo-single") {
                    rule.style.background = `url(${getCurrentImage('logo', config)}) no-repeat  center / contain`;
                }
                // LOGO CENTRAL
                if (rule.selectorText === ".navbar .navbar-logo .logo") {
                    rule.style.background = `url(${getCurrentImage('logo', config)}) no-repeat  center / contain`;
                }

                if (rule.selectorText === ".navbar .navbar-logo .logo-mobile") {
                    rule.style.background = `url(${getCurrentImage('logoMobile', config)}) no-repeat  center / contain`;
                }
                // LOGO LADO IZQUIERDO
                if (rule.selectorText === ".navbar-start-logo .logo") {
                    rule.style.background = `url(${getCurrentImage('logo', config)}) no-repeat center / contain`;
                }

                if (rule.selectorText === ".navbar-start-logo .logo-mobile") {
                    rule.style.background = `url(${getCurrentImage('logoMobile', config)}) no-repeat center / contain`;
                }
                // MODO GUEST
                if (rule.selectorText === ".navbar-guest-logo .logo") {
                    rule.style.background = `url(${getCurrentImage('logo', config)}) no-repeat center / contain`;
                }

                if (rule.selectorText === ".navbar-guest-logo .logo-mobile") {
                    rule.style.background = `url(${getCurrentImage('logoMobile', config)}) no-repeat center / contain`;
                }

                if (rule.selectorText === ".fixed-background") {
                    rule.style.background = `url(${getCurrentImage('backgroundImage', config)}) no-repeat center center fixed`;
                    rule.style.backgroundSize = 'cover';
                }

                if (rule.selectorText === ".auth-card .image-side") {
                    rule.style.background = `black`;
                }
            }
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : loadBackground -> error", error);
    }
}
/**
 * Obtiene el tema del localstorage caso contrario solo obtiene
 * la propieda defaultColor
 * @returns Object
 */
export const getCurrentTheme = () => {
    let config = { defaultColor };
    try {
        if (localStorage.getItem(themeStorageKey)) {
            config = JSON.parse(localStorage.getItem(themeStorageKey));
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : getCurrentTheme -> error", error);
    }
    return config;
};


/**
* Obtiene las configuraciones del thema por producto o rol solicitado
* retorna un objeto con las configuraciones del thema
* @param {Object} productoRol
* @returns Object
*/
export const getTheme = async (productoRol) => {
    if (productoRol === undefined || productoRol === null) {
        return await fetchSinTokenReturnJSON(`api/config/${defaultProd}`, '', 'GET');
    }
    const { product, rol } = productoRol;
    if (product !== undefined) {
        if (rol !== undefined && rol !== null) {
            return await fetchSinTokenReturnJSON(`api/config/${product}/${rol}`, '', 'GET');
        } else {
            return await fetchSinTokenReturnJSON(`api/config/${product}`, '', 'GET');
        }
    }
}

/**
 * Guarda en el storage el objeto config
 * @param {Object} config
 */
export const setCurrentTheme = (config = {
    defaultColor
}) => {

    try {
        localStorage.setItem(themeStorageKey, JSON.stringify(config));
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error)
    }
};


export const clearCurrentTheme = () => {
    localStorage.removeItem(themeStorageKey);
}
